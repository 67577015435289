<template>
  <div class="form-control"
       :class="[{'password-trigger': passwordTrigger, active: isActive}, {'bg-transparent': bgIsTransparent}]">
    <label><input :type="passwordTrigger ? isActive ? 'text' : 'password' : type"
                  :name="name"
                  v-model="inputModel"
                  @input="inputChanged"
                  :class="[{error: errorMessage?.length > 0}]"
                  :disabled="disabled"
                  :minlength="min"
                  :maxlength="max"
                  :placeholder="placeholder ?? ''"
                  :required="required"></label>

    <template v-if="passwordTrigger && inputModel?.length > 0">
      <svg-icon @click="isActive = true" icon="eye" class="show-pass"/>
      <svg-icon @click="isActive = false" icon="eye-slash" class="hide-pass"/>
    </template>

    <error-message v-if="errorMessage?.length > 0"
                   v-html="errorMessageComp"></error-message>
  </div>
</template>

<script>
import ErrorMessageBlock from "@/components/Forms/ErrorMessageBlock/index";

export default {
  name: "InputBlock",
  components: {
    'error-message': ErrorMessageBlock,
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
    },
    inputValue: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {},
    passwordTrigger: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    bgIsTransparent: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 30
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      inputModel: '',
      isActive: false
    }
  },
  mounted() {
    if (this.inputValue) {
      this.inputModel = this.inputValue;
    }
  },
  methods: {
    inputChanged(e) {
      this.$emit('inputChanged', e.target.value);
    }
  },
  computed: {
    errorMessageComp() {
      if (typeof this.errorMessage === 'string') {
        return this.errorMessage;
      } else {
        let msgString = '';
        this.errorMessage.forEach(item => {
          msgString += item;
        });
        return msgString;
      }
    }
  },
  watch: {
    inputValue(val) {
      this.inputModel = val;
    }
  }
}
</script>