<template>
  <div class="page-content profile">
    <progress-indicator v-if="!loaded">
      Loading...
    </progress-indicator>
    <div class="profile-page"
         v-else>
      <div class="account-details-page">
        <div class="account-details">
          <div class="details-left">
            <div class="user-info-block block-defaults">
              <div class="trial_renew_block" v-if="userSubscription.subscription_status === 'trialing'">
                <div><b>Trial</b> until {{formatDate(userSubscription.trial_will_end_at, 'SMonth  day')}}</div>
              </div>

              <div class="user-photo">
                <img :src="avatarUrl"
                     width="112"
                     height="112"
                     alt="User photo">
              </div>
              <div class="user-details">
                <div class="user-details-content">
                  <div class="user-name">{{ userData.name }}</div>
                  <div class="user-position">{{ user.userPosition }}</div>
                </div>

              </div>
              <div class="photo-options">
                <button class="photo-button"
                        style="margin-right: 8px; width: calc(50% - 8px)"
                        type="button"
                        @click="chooseFiles()">{{ userData.avatar ? 'Upload new photo' : 'Upload photo' }}
                  <input id="fileUpload"
                         ref="file"
                         type="file"
                         @change="handleFileUpload()"
                         hidden>
                </button>
                <button class="photo-button"
                        style="margin-left: 8px; width: calc(50% - 8px)"
                        type="button"
                        @click="openModal('showRemovePhoto')">Remove photo</button>
              </div>
              <div class="plans-options">
                <div class="current-plan">
                  <div v-if="userSubscription.subscriber"><b>Current plan</b>: {{userSubscription.plan_name}} <span>(${{userSubscription.amount}} per {{userSubscription.interval}})</span></div>
                  <div v-else><b>Current plan</b>: Free</div>
                  <div v-if="userSubscription.subscriber"><b>Validity</b>: {{formatDate(userSubscription.period_start_date, 'month-day-year')}} - {{formatDate(userSubscription.period_end_date, 'month-day-year')}}</div>
                  <div v-else><b>Validity</b>: -</div>
                  <div v-if="(userSubscription.subscription_status === 'active' || userSubscription.subscription_status === 'trialing') && !userSubscription.canceled_at"><b>Next payment</b>: {{formatDate(userSubscription.period_end_date, 'month-day-year')}}</div>
                  <div v-else><b>Next payment</b>: -</div>
                </div>

                <div class="plans-link">
                  <a :href="subscriptionLink" target="_blank" class="common-btn-purple-transparent">Billing info</a>
                  <a :href="'/choose-plan'" target="_blank" class="common-btn-purple-transparent">Other Plans</a>
                </div>
              </div>
            </div>

            <div class="questions-block block-defaults desktop-visible">
              <div class="text-part">Have any questions? Write to us and we'll be happy to help 😌</div>
              <div class="common-btn-purple" @click="contactUsClickHandler">Contact us</div>
            </div>
          </div>

          <div class="account-settings default-form-block"
               v-if="loaded">
            <div class="form-row">
              <div class="form-col w50p">
                <div class="form-label small">Name</div>
                <div class="form-control">
                  <input-block type="name"
                               name="name"
                               :input-value="userFormData.name"
                               @inputChanged="inputChanged($event, 'userFormData', 'name')"
                               :error-message="errors?.name"
                               required></input-block>
                </div>
              </div>

              <div class="form-col w50p">
                <div class="form-label small">Last Name</div>
                <div class="form-control">
                  <input-block type="surname"
                               name="surname"
                               :input-value="userFormData.surname"
                               @inputChanged="inputChanged($event, 'userFormData', 'surname')"
                               :error-message="errors?.surname"
                               required></input-block>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-col w50p">
                <div class="form-label small">Email</div>
                <div class="form-control">
                  <input-block type="email"
                               name="email"
                               :input-value="userFormData.email"
                               @inputChanged="inputChanged($event, 'userFormData', 'email')"
                               :error-message="errors?.email"
                               disabled></input-block>
                </div>
              </div>

              <div class="form-col w50p">
                <div class="form-label small">Your position</div>
                <div class="form-control">
                  <custom-select :options="options"
                                 :initial-data="initialSelect('position_id')"
                                 :error-message="errors?.position_id ?? null"
                                 :classes="'flat outlined'"
                                 @selectClicked="selectClicked"/>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-col w100p">
                <div class="form-label small">Current password</div>
                <input-block type="password"
                             name="current_password"
                             :max="30"
                             :input-value="userFormData.current_password"
                             @inputChanged="inputChanged($event, 'userFormData', 'current_password')"
                             :error-message="errors?.current_password ?? null"
                             password-trigger
                             required></input-block>
              </div>
            </div>

            <div class="form-row">
              <div class="form-col w100p">
                <div class="form-label small">New password</div>
                <input-block type="password"
                             name="password"
                             :max="30"
                             :input-value="userFormData.password"
                             @inputChanged="inputChanged($event, 'userFormData', 'password')"
                             :error-message="errors?.password ?? null"
                             password-trigger
                             required></input-block>
              </div>
            </div>

            <div class="form-row">
              <div class="form-col w100p">
                <div class="form-label small">Repeat password</div>
                <input-block type="password"
                             name="password_confirmation"
                             :max="30"
                             :input-value="userFormData.password_confirmation"
                             @inputChanged="inputChanged($event, 'userFormData', 'password_confirmation')"
                             :error-message="errors?.password_confirmation ?? null"
                             password-trigger
                             required></input-block>
              </div>
            </div>

            <div class="form-row">
              <div class="response-msg-block-wrap"
                   v-if="successfullSave">
                <div class="response-msg-block success">
                  <div class="response-content">Changes have been successfully saved!</div>
                </div>
              </div>

              <div class="form-col btn-block">
                <div class="common-btn-purple-transparent" @click="cancelBtnClickHandler">Cancel</div>
                <div class="common-btn-purple" @click="saveBtnClickHandler">Save</div>
              </div>
            </div>
          </div>

          <div class="questions-block block-defaults tablet-visible">
            <div class="text-part">
              <strong>Have any questions?</strong>
              Write to us and we'll be happy to help 😌
            </div>

            <div class="btn-block">
              <base-button height="46px"
                           @click="contactUsClickHandler">Contact us
              </base-button>
            </div>
          </div>
        </div>

        <custom-modal :opened="showContactModal"
                      :max-width="580"
                      class-name="contact-us-modal"
                      @closeModal="closeModal('showContactModal')">
          <div class="modal-message textarea-block">

            <template v-if="!contentFormSendSuccessfully">
              <div class="form-row">
                <div class="form-col w100p">
                  <div class="modal-title">
                    <strong>Have any questions?</strong>
                    Write to us and we'll be happy to help 😌
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-col w100p">
                  <div class="form-control">
                    <textarea-block type="content"
                                    name="content"
                                    :input-value="contactFormData.content"
                                    @inputChanged="inputChanged($event, 'contactFormData', 'content')"
                                    :error-message="errors?.content"
                                    required></textarea-block>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-col w100p btn-block">
                  <button type="button"
                          class="btn btn-default"
                          @click="sendContactForm">Contact us
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              Thank you!<br>
              Your message has been sent successfully.
            </template>

          </div>
        </custom-modal>

        <custom-modal :opened="showImageUploadModal"
                      @closeModal="closeImageUploadModal">
          <div class="modal-message textarea-block image-upload-modal">
            <div v-html="imageUploadResult.message"></div>
          </div>
        </custom-modal>

        <custom-modal :opened="showRemovePhoto"
                      :max-width="492"
                      @closeModal="closeModal('showRemovePhoto')">
          <div class="modal-message">
            Do you really want to delete this picture?
          </div>
          <template v-slot:footer-action>
            <div class="btn-block action-btns">
              <base-button @click="removeCurrentPhoto">Yes</base-button>
              <base-button mode="outline"
                           height="40px"
                           width="180px"
                           @click="closeModal('showRemovePhoto')">No
              </base-button>
            </div>
          </template>
        </custom-modal>

      </div>
    </div>
  </div>
</template>

<script>
import PasswordTrigger from '@/components/Forms/PasswordTrigger/index.vue';
import CustomSelect from "@/components/Forms/CustomSelect/index.vue";
import InputBlock from "@/components/Forms/InputBlock/index.vue";
import TextAreaBlock from "@/components/Forms/TextAreaBlock/index.vue";
import {mapGetters} from 'vuex';
import {httpRequest} from "@/api";
import {getCookie} from "@/utils/utils";
import TheNewHeader from "@/components/TheNewHeader/index.vue";
import {formatDate} from "@/utils/utils";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";

export default {
  name: 'Profile',
  components: {
    ProgressIndicator,
    TheNewHeader,
    'password-trigger': PasswordTrigger,
    'input-block': InputBlock,
    'textarea-block': TextAreaBlock,
    'custom-select': CustomSelect,
  },
  data() {
    return {
      user: {},
      userFormData: {
        name: '',
        surname: '',
        email: '',
        position_id: null,
        current_password: '',
        password: '',
        password_confirmation: '',
      },
      avatar: '',
      errors: {},
      options: {},
      file: '',
      contactFormData: {
        content: ''
      },
      userSubscription: {},
      showContactModal: false,
      showImageUploadModal: false,
      showRemovePhoto: false,
      imageUploadResult: {
        success: false,
        message: '',
      },
      loaded: false,
      env: process.env.VUE_APP_URL,
      successfullSave: false,
      title: '',
      contentFormSendSuccessfully: false,
    }
  },
  methods: {
    formatDate,
    closeImageUploadModal() {
      this.showImageUploadModal = false;
      document.body.style.overflow = 'auto';
    },
    async sendContactForm() {
      const formdata = new FormData();
      formdata.append("author_name", this.userFormData.name);
      formdata.append("author_email", this.userFormData.email);
      formdata.append("content", this.contactFormData.content);
      formdata.append("from_url", window.location.origin + this.$route?.path);
      formdata.append("type", "contacts");
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.user.CONTACTS, formdata);

      if (!result?.errors && !result?.message) {
        this.contentFormSendSuccessfully = true;
      } else {
        this.errors = result.errors ? {...result.errors} : {...result.message};
        this.contentFormSendSuccessfully = false;
      }
    },
    chooseFiles() {
      document.getElementById("fileUpload").click()
    },
    async removeCurrentPhoto() {
      const result = await httpRequest('DELETE', process.env.VUE_APP_API_URL + this.$ApiUrls.user.AVATARS);

      if (!result?.errors) {
        this.$store.dispatch('SET_USER_AVATAR', null);
        this.setUserData();

        this.closeModal('showRemovePhoto');
      }
    },
    async handleFileUpload() {
      this.file = this.$refs.file.files[0];
      const typesArray = ['jpg', 'jpeg', 'png', 'svg'];
      const fileSize = this.file.size;
      const minValue = 100;
      const maxValue = 2500;
      const fileType = this.file.type.split('/')[0];
      const imageType = this.file.type.split('/')[1];

      if (fileType !== 'image') {
        this.showImageUploadModal = true;
        this.imageUploadResult.success = false;
        this.imageUploadResult.message = ' ⚠️&nbspWrong file type selected. Only pictures allowed';
        return;
      }

      if (typesArray.includes(imageType)) {
        const reader = new FileReader;
        reader.onload = async (e) => {
          let image = new Image();
          image.src = e.target.result;
          image.onload = async (img) => {
            const width = image.width;
            const height = image.height;

            if (width < minValue || height < minValue || width > maxValue || height > maxValue) {
              this.showImageUploadModal = true;
              this.imageUploadResult.success = false;
              this.imageUploadResult.message = ' ⚠️&nbspWrong picture size selected.<br /> Min 100x100, Max 2500x2500 px allowed.';
              return;
            }

            if (fileSize > 3000000) {
              this.showImageUploadModal = true;
              this.imageUploadResult.success = false;
              this.imageUploadResult.message = ' ⚠️&nbspWrong picture file size.<br /> Max size 3Mb allowed.';
              return;
            }

            let formData = new FormData();
            formData.append('file', this.file);

            const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.user.AVATARS, formData);
            if (!result?.errors) {
              this.$store.dispatch('SET_USER_AVATAR', result?.path);
              this.setUserData();
            }
          }
        };
        reader.readAsDataURL(this.file);
      } else {
        this.showImageUploadModal = true;
        this.imageUploadResult.success = false;
        this.imageUploadResult.message = ' ⚠️&nbspWrong picture format.<br /> Only svg, png, jpg and jpeg allowed.';
      }
    },
    contactUsClickHandler() {
      this.showContactModal = true;
      document.body.style.overflow = 'hidden';
    },
    async saveBtnClickHandler() {
      let formData = new FormData();
      formData.append('name', this.userFormData.name);
      formData.append('surname', this.userFormData.surname === null || this.userFormData.surname === 'null' ? '' : this.userFormData.surname);
      formData.append('email', this.userFormData.email);
      formData.append('position_id', this.userFormData.position_id);
      if (this.userFormData.current_password !== undefined) {
        formData.append('current_password', this.userFormData.current_password);
        formData.append('password', this.userFormData.password);
        formData.append('password_confirmation', this.userFormData.password_confirmation);
      }

      if (this.userFormData?.password?.length > 0 || this.userFormData?.password_confirmation?.length > 0) {
        formData.append('current_password', this.userFormData.current_password);
        formData.append('password', this.userFormData.password);
        formData.append('password_confirmation', this.userFormData.password_confirmation);
      }

      formData.append("_method", "put");

      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.user.PROFILES, formData);

      if (!result?.errors && !result?.message) {
        this.$store.dispatch('SET_USER_DATA', result?.user);
        this.setUserData();
        this.successfullSave = true;

        setTimeout(() => {
          this.successfullSave = false;
        }, 2500);
      } else {
        this.errors = result.errors ? {...result.errors} : {...result.message};
      }
    },
    cancelBtnClickHandler() {
      this.setUserData();
    },
    inputChanged(e, changedObject, changedField) {
      this[changedObject][changedField] = e;
      this.errors = {};
    },
    selectClicked(e) {
      this.userFormData.position_id = e.id;
    },
    initialSelect(field) {
      for (const key in this.options) {
        if (+key === this.userFormData[field]) {
          return this.options[key];
        }
      }
    },
    async getUserPositions() {
      const positions = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.POSITIONS);
      const opts = {};
      if (!positions?.errors && !positions?.message) {
        positions.forEach(position => {
          opts[position.id] = position.name;
        });
        this.options = {...opts};
      }
    },
    async getUserSubscription() {
      const subscription = await httpRequest('GET', process.env.VUE_APP_API_URL + 'api/subscription');
      this.userSubscription = {...subscription};
    },
    async setUserData() {
      this.$nextTick(() => {
        if (this.userData) {
          this.userFormData = {...this.userData};
          this.userFormData.currentPassword = '';
          this.userFormData.newPassword = '';
          this.userFormData.repeatPassword = '';
          this.avatar = this.userData.avatar;
          this.loaded = true;
          this.user.userPosition = this.options[this.userData.position_id];
        }
      });
    },
  },
  async mounted() {
    await this.getUserPositions();
    await this.getUserSubscription();
    await this.setUserData();

    const currentAppId = getCookie('currentAppId');
    if (!currentAppId) {
      this.$store.dispatch('UPDATE_USER_LIMITS');
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'isMobile',
      'sidebarIsCollapsed'
    ]),
    avatarUrl() {
      if (this.userData?.avatar === null || this.userData?.avatar === undefined) {
        return require(`@/assets/images/avatar.png`);
      } else if (this.userData?.avatar?.includes('avatar.png')) {
        return require(`@/assets/images${this.userData.avatar}`);
      } else {
        return process.env.VUE_APP_API_URL + 'storage' + this.userData.avatar;
      }
    },
    hasSubscription() {
      return this.userSubscription?.subscriber;
    },
    subscriptionLink() {
      return this.userSubscription?.url;
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>