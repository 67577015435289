<template>
  <div class="form-control password-trigger" :class="{active: isActive}">
    <label><input :type="isActive ? 'text' : 'password'"
                  :name="name"
                  :value="newValue"
                  :required="required"
                  @input="inputDataChanged"></label>

    <svg-icon @click="isActive = true" icon="eye" class="show-pass"/>
    <svg-icon @click="isActive = false" icon="eye-slash" class="hide-pass"/>
  </div>
</template>

<script>
export default {
  name: "PasswordTrigger",
  props: {
    name: {
      type: String
    },
    val: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      newValue: null
    }
  },
  created() {
    this.newValue = this.val;
  },
  methods: {
    inputDataChanged(e) {
      this.newValue = e.target.value;
      this.$emit('inputChanged', this.newValue);
    }
  }
}
</script>